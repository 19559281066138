/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import GLightbox from "glightbox";
import React, { useEffect } from "react";
import data from "../data/author.json";

const Author = ({ isBg }) => {
  const { author } = data;
  useEffect(() => {
    GLightbox({
      selector: ".glightbox3",
    });
  }, []);

  return (
    // <!-- ========== Author section start ========== -->
    <section
      id="author"
      className={`section-padding authorv2 ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{author.subtitle}</span>
              <h2 className="display-6">{author.title}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row gx-5">
          {/* <!-- author content area start --> */}
          <div
            className="col-lg-6 mb-4 mb-lg-0"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <p>
              The Author, a recipient of the Pro ecclesia Et Pontifice Gold medal award from Pope John Paul II, 
              a ceremonially installed African Elder, a Knight in a western order, a Member and affiliate of 
              various International Service and Charitable Organizations was born in 1957 at the height of the 
              Maumau war in Kenya East Africa. As a young boy he listened to fireside stories from grandparents 
              born into a family that constituted traditional Shamans and Warriors and supra polygamous cultures 
              in Africa before the coming of the missionaries in the 19th and 20th century. He is professionally 
              trained in logistics, Marketing and Governance, and Medical disciplines and was instrumental alongside 
              the Last Of the Consolata Missionaries, Secular and faith based organizations, people of diverse cultures, 
              fraternities and sororities in establishing a school for rehabilitation of mentally handicapped Children 
              in Africa in a culture where such children used to be terminated at birth. Such children were associated 
              with family misfortunes,              
            </p>
            <div className="authorv2__content">
              <div className="authorv2__list">
                <div className="row">
                  {author.userinfo?.map((data, i) => (
                    <div key={i} className="col-6 mb-3">
                      <h4>{data.title}</h4>
                      <p className="mb-0">{data.subtitle}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- author content area end --> */}
          {/* <!-- author content area start --> */}
          <div
            className="col-lg-6"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <p>
              omens, spells, sorcery, witchcraft, the occult and demonic forces.               
              He founded the <a href="http://www.friendsofallamano.com/" target="blank">Friends Of Allamano Special School </a> 
              for disseminating and sharing knowledge and information on Physical and Intellectual Disabilities and undertook 
              studies in esoteric metaphysical subjects to gain deeper insights that would help in promoting disability outreaches 
              beyond the belief in traditional practices based only on superstition and devoid of an amalgam of medical or scientific 
              and cultural study perspectives. <a href="https://www.amazon.com/Bright-Dark-Nights-Soul/dp/1479743208/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=" 
              target="blank">The Bright Dark Nights Of The Soul(2013)</a> highlights some of the challenges faced in such 
              traditional family cultures and in starting and managing such disability outreach projects, aspects of CSR, Faith and 
              Governance and some of the coping mechanisms for those involved, during the DARK NIGHTS and BRIGHT moments along the way: 
              Which for the author included stints in New Age movements and esoteric disciplines as he sought to understand their role 
              in advancing various human disability perspectives.
            </p>
            <div className="authorv2__content">
              <div className="authorv2__list">
                <div className="row">
                  {author.userinfo?.map((data, i) => (
                    <div key={i} className="col-6 mb-3">
                      <h4>{data.title}</h4>
                      <p className="mb-0">{data.subtitle}</p>
                    </div>
                  ))}
                </div>
              </div>
              {/* <ul className="social-icon mt-3">
                {socialLink.facebook === "" ? (
                  ""
                ) : (
                  <li>
                    <a href={socialLink.facebook}>
                      <img
                        className="img-fluid"
                        src="assets/icons/facebook.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </a>
                  </li>
                )}
                {socialLink.youtube === "" ? (
                  ""
                ) : (
                  <li>
                    <a href={socialLink.youtube}>
                      <img
                        className="img-fluid"
                        src="assets/icons/youtube-play.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </a>
                  </li>
                )}
              </ul> */}
            </div>
          </div>
          {/* <!-- author content area end --> */}
        </div>
      </div>
    </section>
    // <!-- ========== Author section end ========== -->
  );
};

export default Author;
