/* eslint-disable jsx-a11y/anchor-is-valid */
import GLightbox from "glightbox";
import React, { useEffect } from "react";
import data from "../data/hero.json";

const Hero = ({ isBg }) => {
  const { hero } = data;

  useEffect(() => {
    GLightbox({
      selector: ".glightbox3",
    });
  }, []);

  return (
    <section
      id="hero"
      className={`hero hero4 hero__padding overflow-hidden position-relative ${
        isBg === "yes" ? "bg-one" : ""
      }`}
    >
      <div className="circle x1"></div>
      <div className="circle x2"></div>
      <div className="circle x3"></div>
      <div className="circle x4"></div>
      <div className="circle x5"></div>
      <div className="container">
        <div className="row gx-5 align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <div className="hero__content position-relative">
              <div className="badge-text mb-2 fs-3 fw-bold"></div>
              <h4 className="display-4 mb-2 fs-1 text-capitalize">
                {hero.name}
              </h4>
              <div className="badge-text mb-3 text-center">{hero.title}</div>
              <p className="mb-5 fs-5 text-center">
                A trilogy from Fatha John Patrick Kamau. The author of <a href="https://www.amazon.com/Bright-Dark-Nights-Soul/dp/1479743208/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=" target="blank">The Bright Dark Nights Of The Soul(2013)</a> and 
                <a href="https://www.amazon.com/BRIGHT-NIGHTS-Drumbeats-Mama-Africa/dp/1499070489/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=" target="blank"> The Bright Nights And Drumbeats From Mama Africa(2015)</a>                
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="hero__author text-center">
              <div className="hero__author--inner">
                <div
                  className="hero__author--inner--pic d-flex align-items-end justify-content-center"
                  style={{ backgroundImage: `url(${hero.image})` }}
                ></div>
                <div className="frame frame-1"></div>
                <div className="frame frame-2"></div>
                <div className="frame frame-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
