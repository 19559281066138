import React from "react";
import Achievement from "../achievement";
import Author from "../author";
import Hero from "../hero";
import Testimonial from "../testimonial";
import Book from "../book";
import Header from "../global/header";
import Footer from "../global/footer";

const Main = ({header, footer}) => {
  const {menuv2} = footer;
  return (
    <>
      <Header header={header} />
      <Hero isBg="" />
      <Author isBg="yes"/>
      <Book isBg=""/>
      <Achievement isBg="yes"/>
      <Testimonial isBg=""/>
      <Footer isBg="" menu={menuv2} />
    </>
  );
};
export default Main;
