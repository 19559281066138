/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import data from "../../data/footer.json";

const Footer = ({ menu, isBg }) => {
  const { footer } = data;

  return (
    <>
      <footer
        className={`footer overflow-hidden ${isBg === "yes" ? "bg-one" : ""}`}
      >
        <div className="container">
          <div className="footer__copyright m-20px-t m-20px-b">
            <div className="row">
              <div className="col-12">
                <p className="m-0 text-center">
                  &copy; {footer.copyright}
                  <a href="#">{footer.name}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
    // <!-- ========== Footer section End ========== -->
  );
};

export default Footer;
