export const siteLogo = {
    logo: "assets/images/drumbeats_logo2.png",
    alt: "Drumbeats From Africa"
};
export const socialLink = {
    facebook: (process.env.REACT_APP_FACEBOOK),
    youtube: (process.env.REACT_APP_YOUTUBE)
};
export const book1 = {
    link: (process.env.REACT_APP_BOOK_1)
};
export const book2 = {
    link: (process.env.REACT_APP_BOOK_2)
};